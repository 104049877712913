<template>
  <h1>Payment failed :(</h1>
</template>

<script>
export default {
  name: 'Fail',
};
</script>

<style scoped></style>
